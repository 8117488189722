import { render, staticRenderFns } from "./JsonEditor.vue?vue&type=template&id=57bca069&scoped=true&"
import script from "./JsonEditor.vue?vue&type=script&lang=js&"
export * from "./JsonEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57bca069",
  null
  
)

export default component.exports