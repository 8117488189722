<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <h1>Application Configs</h1>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <b-button variant="primary" size="sm" @click="add"
          >Create
          <b-icon-plus />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <b-list-group>
          <b-list-group-item
            v-for="config in applicationConfigs"
            :key="config.id"
          >
            <div>
              <span class="mr-3 clickable" @click="edit(config)">{{
                config.id
              }}</span>
              <b-button
                variant="light"
                class="remove-button float-right mt-2"
                size="sm"
                title="remove"
                @click="remove(config)"
              >
                <b-icon-trash />
              </b-button>
            </div>
            <div>
              <small class="text-muted">{{ config.description }}</small>
            </div>
            <div v-if="config.editedAt">
              <small
                v-b-tooltip.hover="editedTooltip(config)"
                class="text-muted d-block text-truncate"
              >
                Last edited: {{ config.editedAt | datetimelongutz }}
              </small>
            </div>
            <div v-else-if="config.createdAt">
              <small
                v-b-tooltip.hover="editedTooltip(config)"
                class="text-muted d-block text-truncate"
              >
                Created: {{ config.createdAt | datetimelongutz }}
              </small>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>

    <b-modal
      v-model="formModal"
      size="lg"
      button-size="sm"
      scrollable
      ok-title="Submit"
      @ok.prevent="submit"
    >
      <application-configs-form
        :config="formItem"
        ref="application-configs-form"
        @submitted="submitted"
      />
    </b-modal>
  </div>
</template>

<script>
import HttpHandler from '@/mixins/HttpHandler';
import ApplicationConfigsForm from '@/components/ApplicationConfigs/ApplicationConfigsForm';

export default {
  name: 'ApplicationConfigs',
  mixins: [HttpHandler],
  components: { ApplicationConfigsForm },
  data() {
    return {
      formModal: false,
      formItem: null,
      applicationConfigs: [
        {
          id: 'dropdown',
          config: '[{"value":null,"text":"select"}]',
          description: 'use this in dropdownlist for platform',
          createdBy: 'someone',
          createdAt: '2022-03-01 10:00:00',
          editedBy: 'someone',
          editedAt: '2022-03-01 10:00:00',
        },
      ],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    editedTooltip(item) {
      let title = '';
      if (item.editedAt) {
        title =
          'Edited by: ' +
          item.editedBy.split('____')[0] +
          '<br/> Edited at: ' +
          this.$options.filters.datetimelong(item.editedAt);
      } else if (item.createdAt) {
        title =
          'Created by: ' +
          item.createdBy.split('____')[0] +
          '<br/> Created at: ' +
          this.$options.filters.datetimelong(item.createdAt);
      }
      return {
        html: true,
        title: title,
        variant: 'dark',
        placement: 'right',
        boundary: 'viewport',
      };
    },
    getList() {
      this.httpclient('api/applicationConfigs').then((resp) => {
        this.applicationConfigs = resp.data;
      });
    },
    add() {
      this.formItem = null;
      this.formModal = true;
    },
    edit(config) {
      this.formItem = config;
      this.formModal = true;
    },
    remove(config) {
      this.$bvModal
        .msgBoxConfirm('Proceed to remove the Config?', {
          centered: true,
          bodyTextVariant: 'danger',
          okTitle: 'Remove',
          okVariant: 'danger',
        })
        .then((value) => {
          if (value) {
            this.httpclient
              .delete(
                'api/applicationConfigs/' +
                  encodeURIComponent(config.id)
              )
              .then(() => {
                this.getList();
                this.$bvModal.msgBoxOk(
                  'The Config, [' +
                    config.id +
                    '] had been removed successfully.',
                  {
                    okVariant: 'success',
                    centered: true,
                  }
                );
              });
          }
        });
    },
    submit() {
      this.$refs['application-configs-form'].submit();
    },
    submitted(isEdit, formItem) {
      if (isEdit) {
        this.$bvModal
          .msgBoxConfirm('Proceed to edit the Config?', { centered: true })
          .then((confirm) => {
            if (confirm) {
              this.httpclient
                .put(
                  'api/applicationConfigs/' + encodeURIComponent(formItem.id),
                  { ...formItem }
                )
                .then(() => {
                  this.getList();
                  this.$bvModal.msgBoxOk(
                    'The Config, [' +
                      formItem.id +
                      '] had been edited successfully.',
                    {
                      okVariant: 'success',
                      centered: true,
                    }
                  );
                })
                .then(() => {
                  this.formModal = false;
                });
            }
          });
      } else {
        this.$bvModal
          .msgBoxConfirm('Proceed to add the Config?', { centered: true })
          .then((confirm) => {
            if (confirm) {
              this.httpclient
                .post('api/applicationConfigs', { ...formItem })
                .then(() => {
                  this.getList();
                  this.$bvModal.msgBoxOk(
                    'The Config, [' +
                      formItem.id +
                      '] had been added successfully.',
                    {
                      okVariant: 'success',
                      centered: true,
                    }
                  );
                })
                .then(() => {
                  this.formModal = false;
                });
            }
          });
      }
    },
  },
};
</script>

<style scoped></style>