<template>
  <div>
    <b-form ref="main-form" @submit.stop.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12" lg="3">ID</b-col>
        <b-col>
          <b-form-input
            autofocus
            v-model="formItem.id"
            size="sm"
            required
            minlength="3"
            maxlength="50"
            :disabled="isEdit"
            @change="
              (e) => {
                this.formItem.id = _alphanumericWithUnderscore(e);
              }
            "
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col cols="12" lg="3">Description</b-col>
        <b-col>
          <b-form-textarea
            v-model="formItem.description"
            size="sm"
            required
            maxlength="100"
          ></b-form-textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" lg="3">Config</b-col>
        <b-col>
          <small class="text-muted mb-2"
            >Save the config as json object/array</small
          >
          <json-editor v-model="formItem.config" />
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import _ from 'lodash';
import JsonEditor from '@/components/UI/JsonEditor';

export default {
  name: 'ApplicationConfigsForm',
  components: { JsonEditor },
  props: ['config'],
  mounted() {
    this.setupValue();
  },
  watch: {
    config() {
      this.setupValue();
    },
  },
  data() {
    return {
      jsonEditorOptions: {
        mode: 'code',
        enableTransform: false,
        enableSort: false,
        history: false,
      },
      formItem: _defaultFormItem(),
      isEdit: false,
    };
  },
  methods: {
    setupValue() {
      const config = { ...this.config };
      if (config) {
        config.config = JSON.parse(config.config);
        this.formItem = config;
        this.isEdit = true;
      } else {
        this.formItem = _defaultFormItem();
        this.isEdit = false;
      }
    },
    submit() {
      const mainForm = this.$refs['main-form'];
      if (!mainForm.reportValidity()) {
        return;
      }
      const config = _.cloneDeep(this.formItem);
      config.config = JSON.stringify(config.config);
      this.$emit('submitted', this.isEdit, config);
    },
  },
};

function _defaultFormItem() {
  return {
    id: '',
    config: {},
    description: '',
    createdBy: '',
    createdAt: '1900-01-01 00:00:00',
    editedBy: '',
    editedAt: '1900-01-01 00:00:00',
  };
}
</script>

<style scoped></style>